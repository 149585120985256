import { hideLoader } from '../loader/loader';
import { windowScrollTo } from '/src/common/js';

const showreelIframe = document.querySelector('.showreel__iframe');
const heroContainer = document.querySelector('.hero__container');

const heroOverlay = document.querySelector('.hero__overlay');
const innerShowreel = document.querySelector('.showreel__inner');
const innerProjects = document.querySelector('.projects__inner');
const innerSkills = document.querySelector('.skills__inner');
// const innerMD = document.querySelector('.motion-design__inner');
// const projects = document.querySelector('#projects');

let windowInnerHeight = window.innerHeight;
let initTranslateY = windowInnerHeight * 0.25;
let scroollTop = window.scrollY; // scroollTop = window.pageYOffset; // - альтернатива

showreelIframe.addEventListener('load', onShowreelIframeLoad);
window.addEventListener('load', onWindowLoad);
window.addEventListener('scroll', onScroll);
window.addEventListener('resize', setSectionInitSize);

function onWindowLoad(e) {
  e.preventDefault();
  setSectionInitSize();

  if(!location.hash) windowScrollTo(0);
  // if(location.hash === "#projects") windowScrollTo(projects?.getBoundingClientRect().top - initTranslateY - 60);
}

function setSectionInitSize() {
  windowInnerHeight = window.innerHeight;
  initTranslateY = windowInnerHeight * 0.25;
  document.documentElement.style.setProperty( '--viewport-height', `${window.innerHeight}px` );
  document.documentElement.style.setProperty( 'scroll-padding-top', `${initTranslateY + 60}px` );
  innerShowreel.style.cssText = `transform: translate3d(0, ${ initTranslateY * -2 }px, 0)`;
  innerProjects.style.cssText = `transform: translate3d(0, ${ initTranslateY * -1 }px, 0)`;
  innerSkills.style.cssText = `transform: translate3d(0, ${ initTranslateY * -1 }px, 0)`;
  // innerMD.style.cssText = `transform: translate3d(0, ${initTranslateY * -1}px, 0)`;
  document.querySelector('main').style.cssText = `margin-bottom: ${ initTranslateY * -1 }px`;


  // extra
  const restPverlay = windowInnerHeight - initTranslateY;
  let heroContainerStyle = `height: ${windowInnerHeight * 0.75}px`; 
  let heroContainerMinHeight = 472;

  if (window.innerWidth < 375 && window.innerHeight < 673) heroContainerMinHeight = 542
  if (window.innerWidth >= 375 && window.innerHeight < 635) heroContainerMinHeight = 477
  if (window.innerWidth >= 425 && window.innerHeight < 628) heroContainerMinHeight = 471
  if (window.innerWidth >= 480 && window.innerHeight < 710) heroContainerMinHeight = 533
  if (window.innerWidth >= 768 && window.innerHeight < 698) heroContainerMinHeight = 524
  if (window.innerWidth >= 1024 && window.innerHeight < 735) heroContainerMinHeight = 552
  if (window.innerWidth >= 1200 && window.innerHeight < 794) heroContainerMinHeight = 596
  
  const delta = heroContainerMinHeight - restPverlay;
  const heroContainerPaddingTop = delta > 0 ? 124 - delta : 124
  if (delta > 0) heroContainerStyle = `height: ${windowInnerHeight * 0.75}px; padding-top: ${heroContainerPaddingTop < 60 ? 60 : heroContainerPaddingTop}px`;
  if (delta > 0 && window.innerWidth >= 768) heroContainerStyle = `height: ${windowInnerHeight * 0.75}px; padding-bottom: 24px; padding-top: ${heroContainerPaddingTop + 36 < 60 ? 60 : heroContainerPaddingTop + 36}px`;

  heroContainer.style.cssText = heroContainerStyle;
 }

function onShowreelIframeLoad(e) {
  windowInnerHeight = window.innerHeight;
  initTranslateY = windowInnerHeight * 0.25;
  const restPverlay = windowInnerHeight - initTranslateY;
  let heroContainerStyle = `height: ${windowInnerHeight * 0.75}px`; 
  let heroContainerMinHeight = 472;

  if (window.innerWidth < 375 && window.innerHeight < 673) heroContainerMinHeight = 542
  if (window.innerWidth >= 375 && window.innerHeight < 635) heroContainerMinHeight = 477
  if (window.innerWidth >= 425 && window.innerHeight < 628) heroContainerMinHeight = 471
  if (window.innerWidth >= 480 && window.innerHeight < 710) heroContainerMinHeight = 533
  if (window.innerWidth >= 768 && window.innerHeight < 698) heroContainerMinHeight = 524
  if (window.innerWidth >= 1024 && window.innerHeight < 735) heroContainerMinHeight = 552
  if (window.innerWidth >= 1200 && window.innerHeight < 794) heroContainerMinHeight = 596
  
  const delta = heroContainerMinHeight - restPverlay;
  const heroContainerPaddingTop = delta > 0 ? 124 - delta : 124
  if (delta > 0) heroContainerStyle = `height: ${windowInnerHeight * 0.75}px; padding-top: ${heroContainerPaddingTop < 60 ? 60 : heroContainerPaddingTop}px`;
  if (delta > 0 && window.innerWidth >= 768) heroContainerStyle = `height: ${windowInnerHeight * 0.75}px; padding-bottom: 24px; padding-top: ${heroContainerPaddingTop + 36 < 60 ? 60 : heroContainerPaddingTop + 36}px`;

  const setTimeout1 = setTimeout(() => {
    heroOverlay.style.cssText = `transform: translate3d(0, ${ initTranslateY * -1 }px, 0)`;
    heroContainer.style.cssText = heroContainerStyle;
    clearInterval(setTimeout1); 
  }, 500);

  const setTimeout2 = setTimeout(() => {
    innerShowreel.style.setProperty('opacity', '1');
    clearInterval(setTimeout2);
  }, 500);
  hideLoader();
}

function onScroll(e) {
  scroollTop = window.scrollY;
  windowInnerHeight = window.innerHeight;
  initTranslateY = windowInnerHeight * 0.25;
  document.body.style.setProperty('--scroollTop', `${scroollTop}px`);
  heroOverlay.style.cssText = `transform: translate3d(0, ${ (initTranslateY - scroollTop / 10) * -1 }px, 0)`;
  innerShowreel.style.cssText = `transform: translate3d(0, ${ (2 * initTranslateY - scroollTop / 2.5) * -1 }px, 0)`;
}


// function onResize(e) {
//   console.log('onResize');
//   windowInnerHeight = window.innerHeight;
//   initTranslateY = windowInnerHeight * 0.25;
//   const restPverlay = windowInnerHeight - initTranslateY;
//   let heroContainerStyle = `height: ${windowInnerHeight * 0.75}px`; 
//   let heroContainerMinHeight = 472;

//   if (window.innerWidth < 375 && window.innerHeight < 673) heroContainerMinHeight = 542
//   if (window.innerWidth >= 375 && window.innerHeight < 635) heroContainerMinHeight = 477
//   if (window.innerWidth >= 425 && window.innerHeight < 628) heroContainerMinHeight = 471
//   if (window.innerWidth >= 480 && window.innerHeight < 710) heroContainerMinHeight = 533
//   if (window.innerWidth >= 768 && window.innerHeight < 698) heroContainerMinHeight = 524
//   if (window.innerWidth >= 1024 && window.innerHeight < 735) heroContainerMinHeight = 552
//   if (window.innerWidth >= 1200 && window.innerHeight < 794) heroContainerMinHeight = 596
  
//   const delta = heroContainerMinHeight - restPverlay;
//   const heroContainerPaddingTop = delta > 0 ? 124 - delta : 124
//   if (delta > 0) heroContainerStyle = `height: ${windowInnerHeight * 0.75}px; padding-top: ${heroContainerPaddingTop < 60 ? 60 : heroContainerPaddingTop}px`;
//   if (delta > 0 && window.innerWidth >= 768) heroContainerStyle = `height: ${windowInnerHeight * 0.75}px; padding-bottom: 24px; padding-top: ${heroContainerPaddingTop + 36 < 60 ? 60 : heroContainerPaddingTop + 36}px`;

//   heroContainer.style.cssText = heroContainerStyle;
// }

// function getScroollTopElem(elem) {
//   return elem.getBoundingClientRect().top + window.scrollY;
// }

// if (location.hash) {
//   // const section = document.querySelector('#projects');
//   const section = document.querySelector(`${location.hash}`);
//   const sectionTop = section.offsetTop;
//   windowScrollTo(sectionTop)

// }
