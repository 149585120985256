const refs = {
  body: document.querySelector('body'),
  header: document.querySelector('header'),
  loader: document.querySelector('.loader'),
  modalVideo: document.querySelector('.modal-video'),


  
  // header: document.querySelector('header'),
  // btnUp: document.querySelector('.btn-up'),
};
export default refs;