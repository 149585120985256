import { hideElement, showElement } from "/src/common/js";

const bodyRef = document.querySelector('body');
const modalContactMeRef = document.querySelector('.modal-contact-me');
const modalContactMeBackdropRef = modalContactMeRef.querySelector('.modal-contact-me__backdrop ');
const modalContactMeBtnCloseRef = modalContactMeRef.querySelector('.modal-contact-me__btn--close');
document.addEventListener('click', onModalBtnOpenClick);

function onModalBtnOpenClick(e) {
  if (!e.target.classList.contains('modal-contact-me__btn--open')) return;
  openModal();
}
function openModal() {
  showElement(modalContactMeRef);
  bodyRef.classList.add('modalIsOpen');

  modalContactMeBackdropRef.addEventListener('click', onBackdropClick);
  window.addEventListener('keydown', onEscKeyPress);
  modalContactMeBtnCloseRef.addEventListener('click', closeModal);
}
function closeModal() {
  hideElement(modalContactMeRef);
  bodyRef.classList.remove("modalIsOpen");

  modalContactMeBackdropRef.removeEventListener('click', onBackdropClick);
  window.removeEventListener('keydown', onEscKeyPress);
  modalContactMeBtnCloseRef.removeEventListener('click', closeModal);
}
function onBackdropClick(e) {
  if (e.currentTarget === e.target) closeModal();
}
function onEscKeyPress(e) {
  if (modalContactMeBackdropRef.classList.contains('isHidden')) return;
  if (e.code === 'Escape') closeModal();
}