import { hideElement, showElement } from "../../common/js"; 

const mobileMenuRef = document.querySelector('[data-mobile-menu]')
const mobileMenuBtnRef = document.querySelector('[data-mobile-menu-btn]');

updateSize();

window.addEventListener("resize", updateSize);
mobileMenuBtnRef.addEventListener('click', toggleBurgerMenu);
mobileMenuRef.addEventListener('click', toggleBurgerMenu);

function toggleBurgerMenu(e) {
  const expanded = mobileMenuBtnRef.getAttribute('aria-expanded') === 'true' || false;

  if (expanded) hideElement(mobileMenuRef);
  else showElement(mobileMenuRef);

  mobileMenuBtnRef.setAttribute('aria-expanded', !expanded);
  document.body.classList.toggle('mobileMenuIsOpen');
}
export function hideBurgerMenu() {
  hideElement(mobileMenuRef);
  mobileMenuBtnRef.setAttribute('aria-expanded', false);
  document.body.classList.remove('mobileMenuIsOpen');
}
function showBurgerMenu() {
  showElement(mobileMenuRef);
  mobileMenuBtnRef.setAttribute('aria-expanded', true);
  document.body.classList.remove('mobileMenuIsOpen');
}

function updateSize() {
  const innerWidth = window.innerWidth;
 
  if (innerWidth < 768) hideBurgerMenu()
  else showBurgerMenu();
}
