import { hideElement, showElement } from "/src/common/js";

const bodyRef = document.querySelector('body');
const modalShowreelRef = document.querySelector('.modal-showreel');
const modalShowreelBackdropRef = modalShowreelRef?.querySelector('.modal-showreel__backdrop ');
const modalShowreelBtnCloseRef = modalShowreelRef?.querySelector('.modal-showreel__btn--close');
const modalShowreelBtnOpenRef = document.querySelector('.modal-showreel__btn--open');
const currentSrc = modalShowreelRef?.querySelector('iframe').getAttribute('src');

modalShowreelBtnOpenRef?.addEventListener('click', onModalBtnOpenClick);

function onModalBtnOpenClick() {
  modalShowreelRef.querySelector('iframe').setAttribute('src', `${currentSrc}&autoplay=1`);

  const openMod = setTimeout(() => {
    openModal();
    clearInterval(openMod)
  }, 800)
}
function openModal() {
  showElement(modalShowreelRef);
  bodyRef.classList.add('modalIsOpen');

  modalShowreelBackdropRef.addEventListener('click', onBackdropClick);
  window.addEventListener('keydown', onEscKeyPress);
  modalShowreelBtnCloseRef.addEventListener('click', closeModal);
}
function closeModal() {
  hideElement(modalShowreelRef);
  bodyRef.classList.remove("modalIsOpen");

  modalShowreelBackdropRef.removeEventListener('click', onBackdropClick);
  window.removeEventListener('keydown', onEscKeyPress);
  modalShowreelBtnCloseRef.removeEventListener('click', closeModal);
  modalShowreelRef.querySelector('iframe').setAttribute('src', `${currentSrc}`);

}
function onBackdropClick(e) {
  if (e.currentTarget === e.target) closeModal();
}
function onEscKeyPress(e) {
  if (modalShowreelBackdropRef.classList.contains('isHidden')) return;
  if (e.code === 'Escape') closeModal();
}