import { hideElement, showElement } from "/src/common/js";

const bodyRef = document.querySelector('body');
const modalVideoRef = document.querySelector('.modal-video');
const modalVideoMiddleBox = document.querySelector('.modal-video__middle');
const modalVideoBackdropRef = modalVideoRef.querySelector('.modal-video__backdrop');
const modalVideoBtnCloseRef = modalVideoRef.querySelector('.modal-video__btn--close');
const modalVideoBtnOpenListRef = document.querySelector('.motion-design__list');
const openShowreelBtn = document.querySelector('.footer__links-list');

modalVideoBtnOpenListRef?.addEventListener('click', onModalBtnOpenClick);
openShowreelBtn.addEventListener('click', openShowreel);

function onModalBtnOpenClick(e) {  
  const modalVideoMarkup = createModalVideoMarkup(e.target.dataset.videonubmer);

  modalVideoMiddleBox.innerHTML = modalVideoMarkup;
  openModal();

  // const openMod = setTimeout(() => {
  //   openModal();
  //   clearInterval(openMod)
  // }, 800)
}
export function openShowreel(e) {
  if (!e.target.classList.contains('data-showreel')) return;

  let modalVideoMarkup;
  if (e.target.dataset.showreel === '2018') modalVideoMarkup = createModalVideoMarkup(3);
  if (e.target.dataset.showreel === '2023') modalVideoMarkup = createModalVideoMarkup(5);

  modalVideoMiddleBox.innerHTML = modalVideoMarkup;
  openModal();
}

function openModal() {
  showElement(modalVideoRef);
  bodyRef.classList.add('modalIsOpen');

  modalVideoBackdropRef.addEventListener('click', onBackdropClick);
  window.addEventListener('keydown', onEscKeyPress);
  modalVideoBtnCloseRef.addEventListener('click', closeModal);
}
function closeModal() {
  hideElement(modalVideoRef);
  bodyRef.classList.remove("modalIsOpen");

  modalVideoBackdropRef.removeEventListener('click', onBackdropClick);
  window.removeEventListener('keydown', onEscKeyPress);
  modalVideoBtnCloseRef.removeEventListener('click', closeModal);
  modalVideoMiddleBox.innerHTML = '';
}
function onBackdropClick(e) {
  if (e.currentTarget === e.target) closeModal();
}
function onEscKeyPress(e) {
  if (modalVideoBackdropRef.classList.contains('isHidden')) return;
  if (e.code === 'Escape') closeModal();
}

function createModalVideoMarkup(number) {
  if(!number) return '';

  return `
    <video class="modal-video__player" autoplay loop playsinline controls>
      <source type="video/mp4" src="/video/motion-design/full/${number}.mp4">
    </video>
  `
}