import refs from "./refs"; 

const heroRef = document.querySelector('#hero');

window.addEventListener('scroll', onWindowScroll);

function onWindowScroll() {
  const windowPageYOffset = window.pageYOffset;
  const heroBottom = heroRef.getBoundingClientRect().bottom

  if (windowPageYOffset > heroBottom / 4) refs.header.classList.add('header--dark');
  else if (windowPageYOffset <= heroBottom / 4) refs.header.classList.remove('header--dark');
}